@import "variables";
@import "simplebar/dist/simplebar.min.css";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.scss";
@import "./layout/sidebar";
@import "./layout/container";
@import "./layout/right-leaf";
@import "./layout/horizontal";
@import "./layout/topbar";
@import "./layout/rtl/rtl";
@import "./components/tab-bar";


/*****************************/
// GENERAL OVERRIDES
/*****************************/

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.form-label {
    margin-bottom: 0.125rem;
    font-size:14px;
}
 .form-control[readonly] {
    background-color: transparent;
    border:0;
    color:#232429;
    font-size:16px;
}

.rdp-form-control{
    &.form-control[readonly] {
        background-color: $white;
        border: 1px solid #fad8bf;
        color:#232429;
        font-size:0.875rem;
        border-top-right-radius: 0.3rem !important;
        border-bottom-right-radius: 0.3rem !important;
        &.is-invalid {
            border: 1px solid #EE1919;
        }
    }
}

.form-select:disabled {
    background-image: none;
}

.form-control-plaintext.form-select:disabled {
    background-color: transparent;
    border: 0;
    padding: 0.375rem 0;
}

.form-control-plaintext {
    font-weight: 600;
}

.form-dirty {
    box-shadow: 0px 0px 2px $primary;
    border-color: $primary;
}


/*****************************/
// OVERRIDES
/*****************************/

.modal-footer {
    padding: 0.2rem 1rem;
}

.input-group .btn {
    border-radius: 4px;
}

.sidebar_badge {
    padding: 0.35em !important;
    border-radius: 0.25em !important;
}

.datePickerReset button{
    position: absolute !important;
    right: 0;
    height: 38px;
}

.py-3-top {
    padding-top: 1rem !important;
}

.py-4-bottom {
    padding-bottom: 1.5rem !important;
  }

/*****************************/
// CUSTOM STILES
/*****************************/

// Height and Width

.full_height {
    height: 100% !important;
}

// Margins

.m-5 {
    margin: 2rem !important;
}
.mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}
.ms-5 {
    margin-left: 2rem !important;
}
.me-5 {
    margin-right: 2rem !important;
}
.my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}
.mt-5 {
    margin-top: 2rem !important;
}
.mb-5 {
    margin-bottom: 2rem !important;
}

.m-6 {
    margin: 3rem !important;
}
.mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}
.ms-6 {
    margin-left: 3rem !important;
}
.me-6 {
    margin-right: 3rem !important;
}
.my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}
.mt-6 {
    margin-top: 3rem !important;
}
.mb-6 {
    margin-bottom: 3rem !important;
}

// Z-index

.z-n1 {
    z-index: -1!important;
}
.z-1 {
    z-index: 1!important;
}
.z-2 {
    z-index: 2!important;
}
.z-3 {
    z-index: 3!important;
}
.z-4 {
    z-index: 4!important;
}
.z-5 {
    z-index: 5!important;
}

//Hover and focus

.hv-75 {
    cursor: pointer;

    :hover {
        opacity: 0.75;
    }
}

// Form

.form-control-plaintext {
    background-image: none;
}


// Titles and typography and components

.section_icon {
    height: 40px;
    width: 40px;
    padding: 0;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 0.2rem 0.5rem rgba(0,0,0,.1);
    margin-right: 1rem;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    font-size: 20px;
}

.section_title {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    padding-top: 6px;
    vertical-align: middle;
}

//Buttons

.btn-circle {
    height: 38px;
    width: 38px;
}

.mini-btn-circle {
    height: 32px;
    width: 32px;
}


//Grid

.grid_task_container {
    height: calc(100vh - 220px);
}

.grid_task_container_tabs {
    min-height: calc(100vh - 220px);
}

.grid_task_container_tabs_fixed {
    height: calc(100vh - 220px);
}

.grid_task_container_short {
    height: calc(100vh - 318px);
}


//Filter results mask
// .filter_result_mask_container {
//     height: calc(100vh - 170px);
// }
.filter_results_mask {
    position: absolute;
    height: 100% !important;
    min-height: calc(100vh - 172px);
    width: calc(100% + var(--bs-gutter-x)) !important;
    top: 0;
    left: -14px;
    background-color: rgba(35, 36, 41, 0.65) ;
}


// Damaged

.nav-item {    
    .btn-close{
      font-size: 10px;
    }
}



// Skeleton loading

.skeleton {
    animation: skeleton-loading 1.5s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        opacity: 0.3;
        //background-color: hsl(200, 20%, 80%);
    }
    100% {
        opacity: 0.8;
        //background-color: hsl(200, 20%, 95%);
    }
}


//Pie chart

.pie-container svg path:last-child {
    cursor:pointer;
}

// Legend

.legend-container {
    margin-top: 1rem;
}
.legend-group {
    margin-bottom: 1rem;
}
.legend-title {
    margin-bottom: 0.5rem;
    font-weight: bold;
}
.legend-line{
    margin-bottom: 0.25rem;
    display: flex;
}
.legend-color {
    display: inline-block;
    height: 8px;
    width: 24px;
    padding: 8px 12px;
    margin-right: 1em;
}
.legend-value {
    display: inline-block;
}

//KQI

#kqi-container {
    min-height: 36px;
}

//Parameters bar
.form-row-title {
    font-size: 20px;
    font-weight: lighter;
    text-transform: uppercase;
    align-self: center;
    margin-bottom: 0;
}

//Alert Styles
.alert-styles{
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999; 
}


.centered-container {
    position: absolute;
    top: 55%;
    left: 45%;
    right: 45%;
}

.hoverable-button-quick-search:hover
{
    background-color: #f0f0f0 !important;
}

ul.k-treeview-group {
    overflow: auto !important;
}


.grid-row-custom {
    position: relative; 
    overflow: visible; 
}

.custom-tooltip {
    display: block; 
    position: absolute; 
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 99;
    white-space: nowrap; 
    width: 100%;  
    
}

  .custom-tooltip ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .custom-tooltip li {
    margin-bottom: 10px;
  }
  
  .custom-tooltip a {
    color: blue;
    text-decoration: underline;
  }

  .DAGMenu {
    position: absolute;
    background-color: white;
    border: 1px solid black;
    list-style-type: none;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    animation: cascade 0.3s ease-out;
    border-radius: 4px;    
  }
    
  .DAGMenu button {
    background: none;
    border: 0.5px solid black;
    padding: 10px;
    width: 100%; // Make buttons full width
    text-align: left;
    cursor: pointer;
  }
  
  .DAGMenu button:hover {
    background-color: #f0f0f0;
  }

  .LineageTooltip {
  background-color: white;
  border: 1px solid black;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  padding: 5px;
  z-index: 1000;
  pointer-events: none; /* Ensure the tooltip does not interfere with mouse events */
  }

  @keyframes cascade {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }

    
  }

  .lineageNoData {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .zoom-buttons {
    position: fixed;
    top: 200px;
    right: 50px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 5px;

    button {
        background-color: white;
        border: 1px solid black;
        padding: 10px;
        border-radius: 4px;
    } 


  }

  .no-bg-button
  {
    background-color: unset !important;
    border: none !important;
    color: inherit !important;
  }

  .no-bg-button:hover
  {
      background-color: unset !important;
      border: none !important;
      color: inherit !important;
  }
